import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "gatsby-theme-material-ui";
import { useMediaQuery } from "@mui/material";

import SEO from "../components/SEO";
import ComingSoonBanner from "../components/ComingSoonBanner";
import Layout from "../components/Layout";
import ContentContainer from "../components/ContentContainer";
import IntermediariesContact from "../components/IntermediariesContact";
import FirstBannerImage from "../images/First-Banner.png";
import SecondBannerImage from "../images/Second-Banner.png";

const IndexPage = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (
        <Layout
            title={
                <>
                    Smarter. Faster. Simpler. <br /> Buy to let mortgages
                </>
            }
        >
            <ComingSoonBanner />

            <ContentContainer
                contentPosition="flex-start"
                image={
                    <Box
                        component="img"
                        src={FirstBannerImage}
                        alt="Smiling man"
                        sx={{
                            height: "auto",
                            width: "100%"
                        }}
                    />
                }
            >
                <Box
                    sx={{
                        my: {
                            xs: 2,
                            sm: 5
                        }
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h2"
                        fontWeight={500}
                        sx={{ fontSize: { xs: "1.625rem", sm: "1.875rem" } }}
                    >
                        Welcome to ModaMortgages. We’re a new specialist mortgage lender making buy
                        to let mortgages smarter, faster and simpler for brokers and landlords.
                    </Typography>
                    <Typography
                        component="div"
                        fontFamily="Hind"
                        sx={{
                            fontSize: "1.125rem"
                        }}
                    >
                        <Box
                            component="p"
                            sx={{
                                my: 3
                            }}
                        >
                            All of our processes are designed to help you, and we’re 100% dedicated
                            to the intermediary market with a willingness to lend.
                        </Box>
                        <Box
                            component="p"
                            sx={{
                                mb: 3
                            }}
                        >
                            Our personalised application process is stripped back to the essentials,
                            offering speed of service, saving time for everyone. With clear criteria
                            up front and a 100% online application, we consistently offer quick
                            processing, backed by a dedicated, straight-talking support team.
                        </Box>
                        <Box
                            component="p"
                            sx={{
                                mb: 3
                            }}
                        >
                            That means you can always stay on top of cases and keep your clients
                            up-to-speed too.
                        </Box>
                    </Typography>
                    <Button color="primary" to="/aboutus">
                        About us
                    </Button>
                </Box>
            </ContentContainer>

            <ContentContainer
                reverse={!isMobile}
                image={
                    <Box
                        component="img"
                        src={SecondBannerImage}
                        alt="Smiling woman"
                        sx={{
                            height: "auto",
                            width: "100%"
                        }}
                    />
                }
            >
                <Box my={5}>
                    <Typography
                        component="h1"
                        variant="h2"
                        fontWeight={500}
                        sx={{ fontSize: { xs: "1.625rem", sm: "1.875rem" } }}
                    >
                        Created with brokers, for brokers
                    </Typography>
                    <Typography
                        component="p"
                        sx={{
                            mt: 3,
                            mb: 4,
                            fontFamily: "Hind",
                            fontSize: "1.125rem"
                        }}
                    >
                        We’ve worked with the intermediary market to build a proposition to meet
                        brokers’ needs in today’s complex world of buy to let.
                    </Typography>
                    <Button color="primary" to="/ourmortgages">
                        Learn about our mortgages
                    </Button>
                </Box>
            </ContentContainer>

            <IntermediariesContact />
        </Layout>
    );
};

export const Head = () => (
    <SEO
        title="ModaMortgages: Specialist buy to let for intermediaries"
        description="Smarter, faster, simpler mortgages • Clear criteria up front • 100% online application • Quick processing • A dedicated, straight-talking support team"
    />
);

export default IndexPage;
