import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "gatsby-theme-material-ui";
import * as React from "react";

import BackgroundImage from "../../images/ContactUsGraphic.svg";
import ContentContainer from "../ContentContainer";

const IntermediariesContact = () => {
    const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            component="section"
            bgcolor="info.main"
        >
            <ContentContainer
                image={
                    isTablet ? null : (
                        <Box
                            component="img"
                            src={BackgroundImage}
                            alt="Arrows"
                            height="100%"
                            width="100%"
                        />
                    )
                }
            >
                <Box
                    sx={{
                        py: {
                            xs: 5,
                            md: 12
                        },
                        display: { sm: "flex", md: "block" },
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: {
                                sm: "72%",
                                md: "100%"
                            }
                        }}
                    >
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{
                                pb: {
                                    xs: 2,
                                    md: 3
                                },
                                fontSize: { xs: "1.625rem", sm: "1.875rem" },
                                fontWeight: 500
                            }}
                        >
                            Get in touch
                        </Typography>
                        <Typography
                            component="p"
                            variant="h3"
                            sx={{
                                pb: {
                                    xs: 2,
                                    md: 3
                                },
                                fontSize: { xs: "1.125rem", sm: "1.25rem" },
                                fontFamily: "Hind"
                            }}
                        >
                            Contact us to find out more. We’ll be accepting applications for our buy
                            to let mortgages in the coming months.
                        </Typography>
                    </Box>
                    <Button
                        sx={{
                            px: 4,
                            width: {
                                xs: "100%",
                                sm: "auto"
                            }
                        }}
                        color="secondary"
                        to="/getintouch"
                    >
                        Get in touch
                    </Button>
                </Box>
            </ContentContainer>
        </Box>
    );
};

export default IntermediariesContact;
