import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import Layout from "../Layout";

const ComingSoonBanner = () => (
    <Box
        textAlign="left"
        sx={{
            fontSize: { xs: "1rem", md: "1.125rem" },
            fontFamily: "Hind",
            bgcolor: "info.main",
            color: "text.primary",
            py: 2,
            px: 0,
            position: "static",
            inset: "auto"
        }}
    >
        <Layout.Content>
            <Link color="text.primary" href="/getintouch" sx={{ fontWeight: "500" }}>
                Get in touch
            </Link>{" "}
            to find out more. We’ll be accepting applications for our buy to let mortgages in the
            coming months.
        </Layout.Content>
    </Box>
);
export default ComingSoonBanner;
