import PropTypes from "prop-types";
import * as React from "react";
import Box from "@mui/material/Box";

import Layout from "../Layout";

const ContentContainer = ({ children, image, reverse, contentPosition, ...rest }) => (
    <Layout.Content
        {...rest}
        BoxProps={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: {
                xs: reverse ? "column-reverse" : "column",
                md: reverse ? "row-reverse" : "row"
            }
        }}
    >
        <Box display="flex" flexDirection="column" justifyContent={contentPosition} flex={1}>
            {children}
        </Box>
        <Box
            sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
            }}
        >
            {image}
        </Box>
    </Layout.Content>
);

ContentContainer.propTypes = {
    children: PropTypes.node.isRequired,
    image: PropTypes.node.isRequired,
    contentPosition: PropTypes.oneOf(["center", "flex-start", "flex-end"]),
    reverse: PropTypes.bool
};

ContentContainer.defaultProps = {
    reverse: false,
    contentPosition: "center"
};

export default ContentContainer;
